@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none !important;
}

@media screen and (max-width: 834px) {
  .spnone {
    display: none;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 65.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #333;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
}

.inner2 {
  margin: 0 auto;
  max-width: 1000px;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 100px;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

@-webkit-keyframes zoom {
  50% {
    -webkit-transform: scale(1.1);
  }
}

@keyframes zoom {
  50% {
    transform: scale(1.1);
  }
}

.hed_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

@media screen and (max-width: 834px) {
  .hed_wrap {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    background: #fff;
    z-index: 1;
  }
}

.hed_R {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .hed_R {
    display: none;
  }
}

.hed_R .time {
  margin-right: 30px;
}

.hed_R .time span {
  background: #003d98;
  color: #fff;
  padding: 5px 10px;
  margin-right: 7px;
  line-height: 1em;
}

.hed_R .tel2 {
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #003d98;
}

@media only screen and (max-width: 960px) {
  .hed_R .tel2 {
    font-size: 1.5em;
  }
}

.hed_R .tel2 a {
  color: #003d98;
  font-weight: bold;
}

.hed_R .tel2 img {
  margin-right: 7px;
}

#siteID {
  text-align: left;
}

@media screen and (max-width: 834px) {
  #siteID img {
    width: 80% !important;
  }
}

#siteID a {
  opacity: 1;
  display: inline-block;
}

.fixed {
  position: fixed !important;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 0 !important;
}

#nav_global {
  transition: 0.5s ease-in-out;
}

#nav_global ul {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  #nav_global ul {
    display: none;
  }
}

#nav_global ul li {
  width: 100%;
  z-index: 1;
  position: relative;
  border-left: 1px solid #cccccc;
}

#nav_global ul li:last-of-type {
  border-right: 1px solid #cccccc;
}

#nav_global ul li a {
  font-weight: bold;
  width: 100%;
  display: block;
  color: #333;
  padding: 15px 0;
  line-height: 1.2em;
  text-align: center;
}

@media only screen and (max-width: 960px) {
  #nav_global ul li a {
    font-size: 14px !important;
  }
}

#nav_global ul li a span {
  display: block;
  font-size: 14px;
  color: #05871d;
}

#nav_global ul ul {
  position: absolute;
  width: 100%;
  z-index: 100;
  display: block;
  background: rgba(255, 255, 255, 0.9);
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

#nav_global ul ul li {
  border-left: none;
}

#nav_global ul ul li a {
  color: #333;
  padding: 15px 0;
  border-bottom: 1px solid #003d98;
}

@media screen and (max-width: 834px) {
  .mainArea {
    margin-top: 72px;
  }
}

.mainArea img {
  width: 100% !important;
  height: auto !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.pankuzu {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 834px) {
  .mainArea2 {
    margin-top: 72px;
  }
}

.mainArea2 #local-keyvisual {
  background: url(../images/home/cont1_bg.jpg) center bottom;
  background: cover;
}

#local-keyvisual {
  position: relative;
  background-position: center bottom;
  z-index: 0;
  position: relative;
}

#local-keyvisual:empty {
  display: none;
}

#local-keyvisual h1, #local-keyvisual .blog_title {
  font-size: 3em;
  z-index: 1;
  position: relative;
  line-height: 1.2em;
  padding: 60px 0;
  color: #fff;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 834px) {
  #local-keyvisual h1, #local-keyvisual .blog_title {
    font-size: 2em;
  }
}

#local-keyvisual h1:empty, #local-keyvisual .blog_title:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
  background-position: center bottom;
}

#local-keyvisual #local-keyvisual-bg:empty, #local-keyvisual .local-keyvisual-bg:empty {
  display: none;
}

.pan1 {
  margin: 20px 0 100px;
  text-align: center;
  font-size: 13px;
}

@media screen and (max-width: 834px) {
  .pan1 {
    margin: 20px 0 50px;
  }
}

.pan1 a {
  color: #333;
}

.toggleMenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 49px;
  width: 49px;
  position: fixed;
  top: 10px;
  right: 5px;
  z-index: 100;
  transition: 0.5s ease-in-out;
  background: #fff;
  border: 2px solid #003d98;
}

.hide {
  transform: translateY(-200%);
}

@media screen and (max-width: 834px) {
  .hide {
    transform: none;
  }
}

.toggleMenu .bars {
  margin: 0 30px 0 12px;
  height: 22px;
  width: 35px;
  position: relative;
}

.toggleMenu a:hover {
  opacity: 1;
}

.toggleMenu .bars span {
  height: 1px;
  background-color: #003d98;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
  position: absolute;
  left: -5px;
}

.toggleMenu .bars span:nth-of-type(1) {
  width: 30px;
  top: 0;
}

.toggleMenu .bars span:nth-of-type(2) {
  width: 30px;
  top: 10px;
}

.toggleMenu .bars span:nth-of-type(3) {
  width: 30px;
  top: 20px;
}

.toggleMenu.isActive .bars span {
  right: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(1), .toggleMenu.isActive .bars span:nth-of-type(3) {
  width: 30px;
  top: 10px;
}

.toggleMenu.isActive .bars span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.toggleMenu.isActive .bars span:nth-of-type(2) {
  opacity: 0;
}

.toggleMenu.isActive .bars span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.overlayMenu {
  width: 100%;
  -webkit-transition: -webkit-transform .8s ease;
  transition: -webkit-transform .8s ease;
  transition: transform .8s ease;
  transition: transform .8s ease, -webkit-transform .8s ease;
  position: fixed;
  top: 0;
  right: 100%;
  bottom: 0;
  z-index: 9;
}

.overlayMenu.isOpened {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.overlayMenu > .outer {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  padding: 130px 50px 0 !important;
}

.overlayMenu > .outer li {
  position: relative;
  text-align: center;
}

.overlayMenu > .outer li a {
  color: #333;
  padding: 15px 0;
  display: block;
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
}

.overlayMenu > .outer li a span {
  font-size: 14px;
  color: #05871d;
  font-weight: bold;
  display: block;
}

.overlayMenu > .outer li ul {
  display: block;
}

.overlayMenu > .outer li ul li {
  width: 100%;
}

.overlayMenu > .outer .sp_menu {
  margin-top: 15px;
}

.overlayMenu > .outer .tel2 {
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #003d98;
}

.overlayMenu > .outer .tel2 a {
  color: #003d98;
  font-weight: bold;
}

.overlayMenu > .outer .tel2 img {
  margin-right: 7px;
}

.overlayMenu > .outer .toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;
  display: block;
  padding: 25px;
  background: #003d98;
}

.overlayMenu > .outer .sp_btn {
  position: relative;
}

.overlayMenu > .outer .sp_btn:before {
  position: absolute;
  top: 0;
  right: -6px;
  bottom: 0;
  display: block;
  margin: auto 0;
  width: 12px;
  height: 1px;
  background-color: #fff;
  content: '';
}

.overlayMenu > .outer .sp_btn:after {
  position: absolute;
  top: 0;
  right: -6px;
  bottom: 0;
  display: block;
  margin: auto 0;
  width: 12px;
  height: 1px;
  background-color: #fff;
  content: '';
  transition: transform .3s;
  transform: rotate(-90deg);
}

.overlayMenu > .outer .on_click:after {
  transform: rotate(0);
}

.overlayMenu:before, .overlayMenu:after {
  content: "";
  height: 20px;
  background-size: cover;
  position: absolute;
  left: 10px;
  right: 10px;
}

.overlayMenu:before {
  top: 0;
}

.overlayMenu:after {
  bottom: 0;
}

@media (max-width: 834px) {
  .pcOnly {
    display: none !important;
  }
}

@media (min-width: 834px) {
  .smOnly {
    display: none !important;
  }
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  text-align: center;
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer #nav_footer {
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul:nth-of-type(1) {
  margin-right: 30px;
}

footer#global_footer #nav_footer li {
  text-align: left;
}

@media screen and (max-width: 640px) {
  footer#global_footer #nav_footer li {
    text-align: center;
  }
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 10px;
  display: block;
  color: #fff !important;
  position: relative;
}

footer#global_footer #nav_footer li a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  left: -1%;
  top: 12px;
}

@media screen and (max-width: 640px) {
  footer#global_footer #nav_footer li a {
    background: #003d98;
    color: #fff;
    padding: 10px;
    border-radius: 50px;
    display: block;
    margin-bottom: 10px;
  }
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff !important;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

#copy {
  color: #fff;
}

.small_bg {
  text-align: center;
  background: #003d98;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  opacity: 1;
}

.pagetop a {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #003d98;
  color: #fff;
  font-weight: bold;
  display: block;
  padding: 14px;
  font-size: 1.5em;
}

.footer1 {
  background: url(../images/home/contact_bg.jpg) center;
  background-size: cover;
  display: flex;
  justify-content: flex-start;
  padding: 100px 20px;
}

@media screen and (max-width: 834px) {
  .footer1 {
    justify-content: center;
  }
}

@media only screen and (max-width: 960px) {
  .footer1 {
    justify-content: center;
  }
}

.footer1 .ft_h2 {
  font-size: 2.5em;
  color: #003d98;
  font-weight: 900;
}

.footer1 .ft_h2 span {
  display: block;
  font-size: 14px;
  color: #05871d;
  position: relative;
  margin-top: 40px;
}

.footer1 .ft_h2 span:before {
  content: url(../images/home/midashi_deco1.png);
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
}

.footer1 .ft_contwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}

@media screen and (max-width: 834px) {
  .footer1 .ft_contwrap {
    display: block;
  }
}

@media only screen and (max-width: 960px) {
  .footer1 .ft_contwrap {
    display: block;
  }
}

.footer1 .ft_contwrap .telwrap {
  background: #003d98;
  padding: 30px;
  height: 117px;
  margin-right: 30px;
  max-width: 348px;
}

@media screen and (max-width: 834px) {
  .footer1 .ft_contwrap .telwrap {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 960px) {
  .footer1 .ft_contwrap .telwrap {
    margin: 0 auto;
  }
}

.footer1 .ft_contwrap .telwrap .time {
  color: #fff;
}

.footer1 .ft_contwrap .tel2 {
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
}

.footer1 .ft_contwrap .tel2 a {
  color: #fff;
  font-weight: bold;
}

.footer1 .ft_contwrap .tel2 img {
  margin-right: 7px;
}

.footer1 .ft_contwrap .fax {
  margin-right: 30px;
  height: 117px;
  background: #3c88a6;
  padding: 30px;
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  max-width: 348px;
}

@media screen and (max-width: 834px) {
  .footer1 .ft_contwrap .fax {
    margin: 15px auto 0;
  }
}

@media only screen and (max-width: 960px) {
  .footer1 .ft_contwrap .fax {
    margin: 15px auto 0;
  }
}

.footer1 .ft_contwrap .fax img {
  margin-right: 7px;
}

.footer1 .ft_contwrap .ft_cont a {
  height: 117px;
  background: #47a5a6;
  padding: 30px;
  font-size: 1.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #fff;
  max-width: 348px;
}

@media screen and (max-width: 834px) {
  .footer1 .ft_contwrap .ft_cont a {
    margin: 15px auto 0;
  }
}

@media only screen and (max-width: 960px) {
  .footer1 .ft_contwrap .ft_cont a {
    margin: 15px auto 0;
  }
}

.footer1 .ft_contwrap .ft_cont a img {
  margin-right: 7px;
}

.footer2 {
  padding: 60px 20px;
  background: #003d98;
  color: #fff;
}

.footer2 .inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 834px) and (max-width: 1200px) {
  .footer2 .inner {
    display: block;
  }
}

@media screen and (max-width: 834px) {
  .footer2 .inner {
    display: block;
  }
}

.footer2 .ft_logo {
  margin-bottom: 30px;
  text-align: left;
}

.footer2 .add {
  text-align: left;
}

.title_01 h2 {
  font-size: 2.6em;
  line-height: 1.2em;
  font-weight: bold;
  color: #fff;
}

.title_01 h2 span {
  display: block;
  font-size: 20px;
}

.title_02 h2 {
  font-size: 2.5em;
  color: #003d98;
  font-weight: 900;
}

.title_02 h2 span {
  display: block;
  font-size: 14px;
  color: #05871d;
  position: relative;
  margin-top: 40px;
}

.title_02 h2 span:before {
  content: url(../images/home/midashi_deco1.png);
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
}

.sub_title_01 h3 {
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
  background: #05871d;
  color: #fff;
}

.txt > div {
  line-height: 2.25em;
}

@media screen and (max-width: 640px) {
  .txt_sp > div {
    text-align: left !important;
  }
}

.cont_01 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  background: url(../images/home/cont1_bg.jpg) center;
  background-size: cover;
  padding: 80px 20px;
}

.cont_01 .inner {
  margin: 0 auto;
}

.cont_01 .txt {
  color: #fff;
}

.cont_01 .in {
  max-width: 970px;
  margin: 0 auto;
}

.cont_02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cont_02 .cont {
  width: 48%;
  border: 1px solid #ccc;
  padding: 40px;
  margin-bottom: 5%;
}

@media screen and (max-width: 640px) {
  .cont_02 .cont {
    width: 100%;
  }
}

.cont_02 .cont .box {
  width: 100% !important;
}

.cont_02 .cont .box:nth-of-type(1) {
  text-align: center;
  margin-bottom: 30px;
}

.cont_02 .cont .box:nth-of-type(1) > div {
  font-weight: bold;
  font-size: 1.25em;
}

.cont_02 .cont .box:nth-of-type(3) div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

@media screen and (max-width: 834px) {
  .cont_02 .cont .box:nth-of-type(3) div {
    display: block;
  }
}

.cont_02 .cont .box:nth-of-type(3) div a {
  width: 48%;
  display: block;
  padding: 15px;
  color: #fff;
  text-align: center;
  font-size: 80%;
}

@media screen and (max-width: 834px) {
  .cont_02 .cont .box:nth-of-type(3) div a {
    width: 100%;
  }
}

.cont_02 .cont .box:nth-of-type(3) div a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  margin-left: 15px;
}

.cont_02 .cont .box:nth-of-type(3) div a:nth-of-type(1) {
  background: #003d98;
}

.cont_02 .cont .box:nth-of-type(3) div a:nth-of-type(2) {
  background: #3c88a6;
}

@media screen and (max-width: 834px) {
  .cont_02 .cont .box:nth-of-type(3) div a:nth-of-type(2) {
    margin-top: 15px;
  }
}

.cont_03 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  background: #f7f7f7;
  padding: 80px 20px;
}

.bnrbox {
  justify-content: space-between;
}

.bnrbox .box {
  width: 48%;
}

@media screen and (max-width: 640px) {
  .bnrbox .box {
    width: 100%;
    margin-bottom: 5%;
  }
}

.insta .sns_list {
  border: none !important;
  flex-direction: inherit !important;
  display: block !important;
}

.insta .sns_list > div {
  border-bottom: 1px solid #ccc !important;
  flex-direction: inherit !important;
}

@media screen and (max-width: 640px) {
  .insta .sns_list > div {
    display: block !important;
  }
}

.insta .sns_date {
  color: #fff !important;
  font-family: a-otf-futo-min-a101-pr6n, serif;
}

.insta .sns_text {
  font-size: 16px !important;
  width: 80% !important;
  color: #fff;
}

@media screen and (max-width: 640px) {
  .insta .sns_text {
    width: 100% !important;
  }
}

.insta .sns_photo {
  width: 20% !important;
}

@media screen and (max-width: 640px) {
  .insta .sns_photo {
    width: 100% !important;
  }
}

.btn a, .btn2 a {
  display: block;
  max-width: 300px;
  background: #003d98;
  color: #fff;
  padding: 15px 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .btn a, .btn2 a {
    margin-right: auto;
    margin-left: auto;
  }
}

.btn a, .btn2 a {
  background: #003d98 !important;
  color: #fff !important;
  width: 300px !important;
  padding: 15px !important;
}

.btn a:hover, .btn2 a:hover {
  background: #05871d !important;
}

.btn a:after, .btn2 a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  margin-left: 15px;
}

@media screen and (max-width: 640px) {
  .btn a, .btn2 a {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.btn2 a {
  margin: 0 auto;
}

.top_blog .blog_list {
  border: none !important;
  max-height: 200px;
  overflow-y: auto;
}

.top_blog .blog_list > div {
  border-bottom: none !important;
}

.top_blog .blog_list > div .blog_photo {
  display: none;
}

.top_blog .blog_list > div .blog_date {
  color: #fff !important;
  background: #05871d;
  padding: 5px 10px;
  font-size: 16px;
  width: 120px;
  text-align: center;
}

.top_blog .blog_list > div .blog_text {
  width: 100% !important;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 20px !important;
  border-bottom: 1px solid #ccc;
}

@media screen and (max-width: 834px) {
  .top_blog .blog_list > div .blog_text {
    display: block;
  }
}

.top_blog .blog_list > div .blog_text .detail {
  display: none;
}

.top_blog .blog_list > div .blog_text h3 {
  margin-bottom: 0 !important;
  width: 80%;
  font-size: 16px !important;
}

@media screen and (max-width: 834px) {
  .top_blog .blog_list > div .blog_text h3 {
    width: 100%;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl, button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl, button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title, .wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2, .item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a, .item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover, .item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.btn a {
  display: block;
  max-width: 300px;
  background: #003d98;
  color: #fff;
  padding: 15px 0;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .btn a {
    margin-right: auto;
    margin-left: auto;
  }
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1200px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1200px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.title_03 h2 {
  font-size: 1.6em;
  line-height: 1.2em;
  color: #333;
  padding: 20px 0 20px 40px;
  font-weight: bold;
  position: relative;
  background: #f7f7f7;
}

.title_03 h2:before {
  content: '';
  width: 8px;
  height: 20px;
  background: #003d98;
  position: absolute;
  left: 20px;
  top: 25px;
}

@media screen and (max-width: 834px) {
  .title_03 h2 {
    font-size: 24px;
  }
}

.sam_list .slick-slider {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 640px) {
  .sam_list .slick-slider {
    display: block;
  }
}

.sam_list .slick-slider .slick-track {
  background: none;
  padding: 0;
}

.sam_list .slick-slider .slick-list {
  background: #f7f7f7;
}

.sam_list .slick-slider .slick-dots {
  position: static;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .sam_list .slick-slider .slick-dots {
    width: 100% !important;
    bottom: -10px;
  }
}

.sam_list .slick-slider .slick-dots li {
  width: calc((100% - var(--gap) * var(--box)) / var(--column));
  height: auto;
  margin: 0 !important;
  --column: 4;
  --box: 3;
  --gap: 20px;
}

.sam_list .slick-slider .slick-slide article > div {
  text-align: center;
  color: #333;
  font-weight: bold;
  padding: 10px;
}

.sam_list .slick-slider .slick-slide img {
  width: auto !important;
  height: auto !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
  margin: 0 auto !important;
}

@media only screen and (max-width: 960px) {
  .sam_list .slick-slider .slick-slide img {
    width: auto !important;
    height: 450px !important;
  }
}

@media screen and (max-width: 640px) {
  .sam_list .slick-slider .slick-slide img {
    height: auto !important;
  }
}

.pager .prev, .pager .next {
  background: #05871d;
  padding: 10px;
  color: #fff !important;
  border-radius: 5px;
  display: inline-block;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .itext img {
    max-width: 100% !important;
    float: none !important;
  }
}

.sec_01 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .sec_01 {
    display: block;
  }
}

.sec_01 .flxL {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .sec_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_01 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .sec_01 .flxR {
    width: 100% !important;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 960px) {
  .sec_01 {
    display: block;
  }
  .sec_01 .flxL {
    width: 100%;
    text-align: center;
  }
  .sec_01 .flxR {
    width: 100%;
    margin-top: 30px;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .sec_01 .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media only screen and (max-width: 960px) {
  .sec_01 .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

.list_01 li {
  padding: 10px 0 10px 30px;
  position: relative;
}

.list_01 li:before {
  content: '・';
  position: absolute;
  left: 0;
  top: 10px;
  color: #05871d;
  font-size: 30px;
}

.list_02 li {
  padding: 10px 0 10px 30px;
  position: relative;
}

.list_02 li span {
  position: absolute;
  left: 0;
  top: 10px;
  color: #05871d;
  font-size: 1.2em;
  font-weight: bold;
}

.list_03 li {
  padding: 10px 0;
}

.list_03 li span {
  color: #05871d;
  font-size: 1.2em;
  font-weight: bold;
  display: block;
}

.dl_01 dt {
  padding: 20px !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dt {
    width: 100% !important;
  }
}

.dl_01 dd {
  padding: 20px !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dd {
    width: 100% !important;
  }
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.imgbox {
  justify-content: flex-start;
}

.imgbox .box {
  width: 30%;
}

.imgbox .box:nth-of-type(1) {
  margin-right: 15px;
}

@media screen and (max-width: 640px) {
  .imgbox .box:nth-of-type(1) {
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin-bottom: 5%;
  }
}

.btnbox {
  justify-content: flex-start;
}

@media screen and (max-width: 834px) {
  .btnbox {
    justify-content: space-between;
  }
}

.btnbox .box {
  width: 23.333%;
  text-align: center;
  margin-right: 15px;
  margin-bottom: 15px;
}

@media screen and (max-width: 834px) {
  .btnbox .box {
    width: 48%;
    margin-right: 0;
  }
}

.btnbox .box:nth-of-type(4n) {
  margin-right: 0;
}

.btnbox .box a {
  background: #05871d;
  color: #fff;
  padding: 10px;
  display: block;
  position: relative;
}

.btnbox .box a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  margin-left: 15px;
}

.btnbox2 {
  justify-content: flex-start;
}

@media screen and (max-width: 834px) {
  .btnbox2 {
    justify-content: space-between;
  }
}

.btnbox2 .box {
  width: 31.333%;
  text-align: center;
  margin-right: 15px;
  margin-bottom: 15px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .btnbox2 .box {
    width: 48%;
    margin-right: 0;
  }
}

@media screen and (max-width: 640px) {
  .btnbox2 .box {
    width: 100%;
    margin-right: 0;
  }
}

.btnbox2 .box:nth-of-type(3n) {
  margin-right: 0;
}

.btnbox2 .box a {
  background: #003d98;
  color: #fff;
  padding: 10px;
  display: block;
  position: relative;
}

.btnbox2 .box a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  margin-left: 15px;
}

.qa {
  border-bottom: 1px solid #fff;
}

.qa .box:nth-of-type(1) {
  display: block !important;
  width: 100% !important;
  border: none !important;
  position: relative;
  padding: 20px 0 20px 60px !important;
  background: #003d98;
  color: #fff;
}

.qa .box:nth-of-type(1) b {
  display: block;
}

.qa .box:nth-of-type(1):before {
  content: "Q";
  display: inline-block;
  padding: 0em;
  color: white;
  text-align: center;
  position: absolute;
  left: 20px;
  top: 17px;
  font-weight: bold;
  font-size: 1.5em;
}

@media screen and (max-width: 834px) {
  .qa .box:nth-of-type(1) {
    padding-right: 45px !important;
  }
}

.qa .box:nth-of-type(1):after {
  font-family: "Font Awesome 5 pro";
  content: "\f105";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 40px;
  /*サイズ*/
  right: 2%;
  /*アイコンの位置*/
  top: 20px;
  /*アイコンの位置*/
  font-weight: 300;
  color: #fff;
}

.qa .box:nth-of-type(2) {
  width: 100% !important;
  border: none !important;
  padding: 15px 0 15px 60px !important;
  position: relative;
}

.qa .box:nth-of-type(2) b {
  display: block;
}

.qa .box:nth-of-type(2):before {
  content: "A";
  display: inline-block;
  padding: 0em;
  color: #05871d;
  text-align: center;
  margin-right: 7px;
  position: absolute;
  left: 20px;
  top: 17px;
  font-weight: bold;
  font-size: 1.5em;
}

.gmap iframe {
  width: 100%;
  vertical-align: bottom;
}

.p_cont h2 {
  font-size: 1.5em;
  font-weight: bold;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  background: #05871d;
  padding: 15px;
  margin-bottom: 15px;
  color: #fff;
}

.mail {
  display: none;
}

.cont_tel > div {
  font-size: 2em;
  display: flex;
  align-items: center;
}

.cont_tel > div .tel2 {
  display: flex;
  align-items: center;
  font-family: 'M PLUS Rounded 1c', sans-serif;
  margin-left: 15px;
  color: #1c1c1c;
  font-weight: bold;
}

.cont_tel > div .tel2 a {
  color: #1c1c1c;
  font-weight: bold;
}

.cont_tel > div .tel2 img {
  margin-right: 7px;
}

.confirm {
  margin-top: 50px;
}
